import request from '@/utils/oms-request'

const oms_base = process.env.NODE_ENV === 'production' ? 'https://oms.wangoon.cn' : 'http://oms.test.wangoon.cn'
export default oms_base

export function unifiedExport(data) {
  return request({
    url: 'oms-export/execute-export/execute',
    method: 'post',
    data
  })
}

export function uploadOSS(data) {
  return request({
    url: process.env['VUE_APP_UPLOAD_API'],
    method: 'post',
    data
  })
}

export function getAllUser(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_API}erp-api/base/allUser`,
    method: 'post',
    data
  })
}
